// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
  display: grid;
  grid-template-columns: 1fr 1fr; /* Two columns with equal width */
  grid-template-rows: auto;
  gap: 1%;
  margin-top: 5%;
  margin-bottom: 5%;
}

.formBox {
  width: 80%;
  justify-self: center;
  align-self: start; 
}

.submitButton {
  background-color: #747474 !important;
}

.responseBox {
  width: 90%;
}

.titles {
  font-size: x-large;
  margin-bottom: 2%;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B,EAAE,iCAAiC;EACjE,wBAAwB;EACxB,OAAO;EACP,cAAc;EACd,iBAAiB;AACnB;;AAEA;EACE,UAAU;EACV,oBAAoB;EACpB,iBAAiB;AACnB;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,kBAAkB;EAClB,iBAAiB;AACnB","sourcesContent":[".container {\n  display: grid;\n  grid-template-columns: 1fr 1fr; /* Two columns with equal width */\n  grid-template-rows: auto;\n  gap: 1%;\n  margin-top: 5%;\n  margin-bottom: 5%;\n}\n\n.formBox {\n  width: 80%;\n  justify-self: center;\n  align-self: start; \n}\n\n.submitButton {\n  background-color: #747474 !important;\n}\n\n.responseBox {\n  width: 90%;\n}\n\n.titles {\n  font-size: x-large;\n  margin-bottom: 2%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
