// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.topbar {
    width: 100%;
    height: 65pt;
    background-color: #343434;
}

.topbarWrapper {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 2%;
    margin-right: 2%;
    font-size: 30px;
    color: whitesmoke;
    font-weight: bold;
}

.right-side {
    display: flex;
    justify-content: space-between;
    margin-right: 5%;
}

.RSelement {
    display: flex;
    justify-content: space-between;
    margin-right: 10%;
    cursor: pointer;
    font-size: 30px;
    color: whitesmoke;
    text-decoration: none;
}

.title {    
    display: flex;
    align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/topbar/topbar.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,yBAAyB;AAC7B;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,eAAe;IACf,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,iBAAiB;IACjB,eAAe;IACf,eAAe;IACf,iBAAiB;IACjB,qBAAqB;AACzB;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB","sourcesContent":[".topbar {\r\n    width: 100%;\r\n    height: 65pt;\r\n    background-color: #343434;\r\n}\r\n\r\n.topbarWrapper {\r\n    height: 100%;\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: space-between;\r\n    margin-left: 2%;\r\n    margin-right: 2%;\r\n    font-size: 30px;\r\n    color: whitesmoke;\r\n    font-weight: bold;\r\n}\r\n\r\n.right-side {\r\n    display: flex;\r\n    justify-content: space-between;\r\n    margin-right: 5%;\r\n}\r\n\r\n.RSelement {\r\n    display: flex;\r\n    justify-content: space-between;\r\n    margin-right: 10%;\r\n    cursor: pointer;\r\n    font-size: 30px;\r\n    color: whitesmoke;\r\n    text-decoration: none;\r\n}\r\n\r\n.title {    \r\n    display: flex;\r\n    align-items: center;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
